
<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_ttsr',
      headers: [
        [{
          label: 'Tahun Lulus',
          dataKey: 'tahun_lulus',
          attributes: { rowspan: 2 },
        },
        {
          label: 'Jumlah Lulusan',
          dataKey: 'jumlah_lulusan',
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' }
        },
        {
          label: 'Jumlah Lulusan yang Terlacak',
          dataKey: 'jumlah_lulusan_yang_terlacak',
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' }
        }, {
          label: 'Jumlah Lulusan Terlacak dengan Waktu Tunggu Mendapatkan Pekerjaan Pertama',
          attributes: { colspan: 3 },
        }],
        [{
          label: 'Tinggi',
          dataKey: 'tinggi',
          dataAttributes: { class: 'text-center' }
        }, {
          label: 'Sedang',
          dataKey: 'sedang',
          dataAttributes: { class: 'text-center' }
        }, {
          label: 'Rendah',
          dataKey: 'rendah',
          dataAttributes: { class: 'text-center' }
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Lulus',
          name: 'tahun_lulus',
          dataKey: 'tahun_lulus',
          type: 'textarea',
          placeholder: 'TS-1',
          rules: 'required',
        }, {
          label: 'Jumlah Lulusan',
          name: 'jumlah_lulusan',
          dataKey: 'jumlah_lulusan',
          type: 'number',
          
          defaultValue: 0,
        }, {
          label: 'Jumlah Lulusan yang Terlacak',
          name: 'jumlah_lulusan_yang_terlacak',
          dataKey: 'jumlah_lulusan_yang_terlacak',
          type: 'number',
          
          defaultValue: 0,
        }, {
          label: 'Tinggi',
          name: 'tinggi',
          dataKey: 'tinggi',
          type: 'number',
          
          defaultValue: 0,
        }, {
          label: 'Sedang',
          name: 'sedang',
          dataKey: 'sedang',
          type: 'number',
          
          defaultValue: 0,
        }, {
          label: 'Rendah',
          name: 'rendah',
          dataKey: 'rendah',
          type: 'number',
          
          defaultValue: 0,
        }]
      },
    }
  },
}
</script>
